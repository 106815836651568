<template>
    <v-container>
        <!-- DIALOGS -->
        <v-dialog v-model="dialogErro.status" persistent width="500">
          <CardDialog :dialog="dialogErro"/>
        </v-dialog>

        <v-dialog v-model="dialogEdita" persistent max-height="70vh">
          <v-card class="primary fixed-header-card" height="70vh">
            <v-card-title class="white--text pb-2 fixed-header">
              Editar Visita - {{ editaForm.clienteNome }}
            </v-card-title>
            <v-divider />
            <v-card-text class="white scrollable-content">
              <v-stepper v-model="stepEdit" vertical flat>
                <v-stepper-step
                    :complete="stepEdit > 1"
                    step="1"
                    class="px-0 py-1 font-weight-bold"
                    editable
                >Dados do cliente</v-stepper-step>
                <v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
                  <v-row align="end">
                    <v-col cols="12" md="12" class="py-2 pb-xs-0">
                      <v-radio-group hide-details v-model="buscaPersonalizada.tipoEdit" class="ma-0 pb-md-2" row>
                        <v-col cols="8" class="pa-0" style="display: flex">
                          <v-col cols="6" class="pa-0">
                            <v-radio color="red darken-3" label="Cliente" value="C"></v-radio>
                          </v-col>
                          <v-col cols="6" class="pa-0">
                            <v-radio color="red darken-3" label="Novo Cliente" value="NC"></v-radio>
                          </v-col>
                        </v-col>
                      </v-radio-group>

                      <div v-if="buscaPersonalizada.tipoEdit === 'C'">

                        <div class="text-body-1 font-weight-bold">Cliente Comercial</div>
                        <v-autocomplete
                            no-filter
                            v-model="editaForm.cliente"
                            outlined
                            dense
                            :search-input.sync="search2"
                            :items="items2"
                            :loading="carregando"
                            item-text="idenome"
                            item-value="idcliente"
                        ></v-autocomplete>
                      </div>

                      <v-text-field v-model="editaForm.clienteNome" type="text" required outlined dense style="text-transform: uppercase"
                                    label="Nome Cliente" v-if="buscaPersonalizada.tipoEdit === 'NC'" maxlength="100" class="my-2 pt-0 pb-0"></v-text-field>

                      <div class="text-body-1 font-weight-bold" v-if="buscaPersonalizada.tipoEdit === 'NC'">CPF</div>
                      <v-text-field
                          class="mb-n3"
                          ref="CPF"
                          v-model="editaForm.cpfcnpj"
                          v-mask="'###.###.###-##'"
                          outlined
                          dense
                          required
                          v-if="buscaPersonalizada.tipoEdit === 'NC'"
                      ></v-text-field>
                      <v-text-field v-model="editaForm.clienteFone" type="text" required outlined
                                    dense label="Telefone Cliente" @input="formatarTelefone2"
                                    maxlength="15"></v-text-field>
                      <div class="text-body-1 font-weight-bold">Profissional</div>
                      <v-select v-model="editaForm.idTipoProfissional" :items="tipoProfissional" required outlined dense
                                item-text="PROFISSIONAL" item-value="ID"></v-select>
                      <v-text-field v-model="editaForm.mestreObra" type="text" required outlined
                                    dense label="Nome do Profissional" maxlength="100"></v-text-field>
                      <v-text-field v-model="editaForm.mestreFone" type="text" required outlined
                                    dense label="Telefone do Profissional" @input="formatarTelefone4"
                                    maxlength="15"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn
                          :disabled="verificaEditStep1()"
                          color="primary"
                          small
                          @click="stepEdit = 2"
                      >Próximo
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-stepper-content>
                <v-stepper-step
                    :complete="stepEdit > 2"
                    step="2"
                    class="px-0 py-1 font-weight-bold"
                >Dados da obra</v-stepper-step>
                <v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
                  <v-row>
                    <v-col class="py-3" cols="12">
                      <fieldset class="px-4 pb-3">
                        <legend>Endereço</legend>
                        <v-row class="py-3">
                          <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                            <div class="text-body-1 font-weight-bold">Rua</div>
                            <v-text-field class="mb-n3" v-model="editaForm.enderecoRua" outlined dense required maxlength="100"></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="py-0">
                            <div class="text-body-1 font-weight-bold">Bairro</div>
                            <v-text-field class="mb-n3" v-model="editaForm.enderecoBairro" outlined dense required maxlength="100"></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
                            <div class="text-body-1 font-weight-bold">Número</div>
                            <v-text-field class="mb-n3" v-model="editaForm.enderecoNumero" outlined dense required maxlength="6"></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                            <div class="text-body-1 font-weight-bold">Cidade</div>
                            <v-autocomplete
                                no-filter
                                v-model="editaForm.enderecoCidade"
                                outlined
                                dense
                                :search-input.sync="searchCidade"
                                :items="itemsCidades"
                                :loading="carregando3"
                                item-text="cidade"
                                item-value="idcidade"
                            ></v-autocomplete>
                          </v-col>

                        </v-row>
                      </fieldset>
                      <fieldset class="px-4 pb-3">
                        <legend>Tipo de obra</legend>
                        <v-row class="py-4">
                          <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                            <v-radio-group v-model="editaForm.tipoObra" row>
                              <v-radio
                                  label="Casa"
                                  color="red darken-3"
                                  value="1"
                              />
                              <v-radio
                                  label="Sobrado"
                                  color="red darken-3"
                                  value="2"
                              />
                              <v-radio
                                  label="Comercial"
                                  color="red darken-3"
                                  value="3"
                              />
                              <v-radio
                                  label="Industrial"
                                  color="red darken-3"
                                  value="4"
                              />
                              <v-radio
                                  label="Reforma"
                                  color="red darken-3"
                                  value="5"
                              />
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </fieldset>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn
                          color="primary"
                          small
                          plain
                          @click="stepEdit = 1"
                      >Voltar</v-btn>
                      <v-btn
                          :disabled="verificaEditStep2()"
                          color="primary"
                          small
                          @click="stepEdit = 3"
                      >Próximo</v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step
                    :complete="stepEdit > 3"
                    step="3"
                    class="px-0 py-1 font-weight-bold"
                >Etapa da Obra</v-stepper-step>
                <v-stepper-content step="3" class="px-2 mx-0 font-weight-bold">
                  <v-row>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-4">
                      <div class="text-body-1 font-weight-bold">Selecione a etapa</div>
                      <v-select v-model="editaForm.etapasObra" :items="etapasObra" required outlined dense
                                item-text="ETAPA" item-value="ID"></v-select>

                      <!--
                      <v-card v-if="editaForm.etapasObra != null" class="primary white--text" elevation="0"
                              style="border: 1px solid #023449">
                        <v-card-title class="pa-2 text-center align-center justify-center">
                          Grupos de produtos a oferecer
                        </v-card-title>
                        <v-card-text class="card-itens-oferecer pa-0">
                          <p class="text-body-1 font-weight-bold pt-4 pl-4 pb-4 ma-0">{{ formVisita.itensOferecer.join(", ") }}</p>
                        </v-card-text>
                      </v-card>
                      -->

                      <div class="text-body-1 font-weight-bold pb-0 pt-6" v-if="editaForm.etapasObra != null">Status</div>
                      <v-radio-group v-model="editaForm.statusEtapa" row class="ma-0" v-if="editaForm.etapasObra != null">
                        <v-radio
                            label="Vendendo"
                            color="red darken-3"
                            value="1"
                            class="py-1"
                        />
                        <v-radio
                            label="Vendido"
                            color="red darken-3"
                            value="3"
                            class="py-1"
                        />
                        <v-radio
                            label="Não vendido"
                            color="red darken-3"
                            value="4"
                            class="py-1"
                        />
                      </v-radio-group>
                      <v-col cols="12" md="4" v-if="editaForm.etapasObra != null" class="pl-0 pt-0 py-0">
                        <div class="text-body-1 font-weight-bold pb-0 pt-2">Data de previsão conclusão etapa</div>
                        <InputDatePicker :disabled="carregando" v-model="editaForm.dataConclusao" @change="verificaDataConclusaoEdit()"
                                         :outlined="true" :dense="true"/>
                      </v-col>
                      <v-col cols="12" v-if="editaForm.etapasObra != null" class="pl-0 pt-0 py-0">
                        <v-textarea
                            class="pt-4 pb-0"
                            filled
                            auto-grow
                            v-model="editaForm.obsObra"
                            label="Observação"
                            maxlength="100"
                        ></v-textarea>
                      </v-col>
                    </v-col>
                    <v-col cols="12">
                      <v-btn small text block color="primary" class="mb-1" @click="stepEdit = 2">Voltar</v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>

              </v-stepper>
            </v-card-text>
            <v-divider />
            <v-card-actions class="fixed-footer">
              <v-spacer />
              <v-btn color="green darken-3" elevation="0" class="px-10" @click="editaVisita" :disabled="verificaVisitaEdit() || carregando2">Editar</v-btn>
              <v-btn color="red darken-3" elevation="0" class="px-10" @click="cancelarEditar">Cancelar</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirma" height="350" width="450">
          <v-card class="pa-5">
            <v-card-title class="text-h4">
              <span class="mx-auto">EXCLUSÃO</span>
            </v-card-title>
            <div class="text-center py-3">
              <v-icon size="100" color="red darken-3">mdi-trash-can</v-icon>
            </div>
            <v-card-text class="text-h6 text-center">Você tem certeza que deseja excluir o registro desta visita?</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green darken-3" elevation="0" class="px-10" @click="deletaVisita(deletar)">Sim</v-btn>
              <v-btn color="red darken-3" elevation="0" class="px-10" @click="dialogConfirma = false">Não</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-card class="primary">
            <v-card-title>
              <v-tabs v-model="tab" grow color="red darken-3" background-color="primary" dark>
                <v-tabs-slider color="red"></v-tabs-slider>
                <v-tab href="#tab-1" class="primary red--text">
                  <v-icon color="red">mdi-office-building-plus</v-icon>
                  <span class="ml-2">Nova visita</span>
                </v-tab>
                <v-tab href="#tab-2" class="primary red--text ml-3">
                  <v-icon color="red">mdi-office-building-marker</v-icon>
                  <span class="ml-2">Visitas Cadastradas</span>
                </v-tab>
              </v-tabs>
            </v-card-title>
            <v-card-text>
              <v-tabs-items v-model="tab" class="pa-0">
                <v-tab-item value="tab-1">
                  <v-card>
                    <v-card-title>Cadastro de Visita</v-card-title>
                    <v-card-text>
                      <v-stepper v-model="step" vertical flat>
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                            class="px-0 py-1 font-weight-bold"
                            editable
                        >Dados do cliente</v-stepper-step>
                        <v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
                          <v-row align="end">
                            <v-col cols="12" md="12" class="py-2 pb-xs-0">
                              <v-radio-group hide-details v-model="buscaPersonalizada.tipo" class="ma-0 pb-md-2" row>
                                <v-col cols="8" class="pa-0" style="display: flex">
                                  <v-col cols="6" class="pa-0">
                                    <v-radio color="red darken-3" label="Cliente" value="C"></v-radio>
                                  </v-col>
                                  <v-col cols="6" class="pa-0">
                                    <v-radio color="red darken-3" label="Novo Cliente" value="NC"></v-radio>
                                  </v-col>
                                </v-col>
                              </v-radio-group>

                              <div v-if="buscaPersonalizada.tipo === 'C'">
                                <v-switch
                                    v-model="tipoCliente"
                                    label="Tipo Cliente"
                                ></v-switch>
                                <div class="text-body-1 font-weight-bold" v-if="tipoCliente">Cliente Cadastrado Gestor</div>
                                <div class="text-body-1 font-weight-bold" v-else>Cliente CISS</div>
                                <v-autocomplete
                                    no-filter
                                    v-model="formVisita.clienteNome"
                                    outlined
                                    dense
                                    :search-input.sync="searchClienteGestor"
                                    :items="itemsGestor"
                                    :loading="carregando"
                                    item-text="idenome"
                                    item-value="NOME"
                                    v-if="tipoCliente"
                                ></v-autocomplete>
                                <v-autocomplete
                                    no-filter
                                    v-model="formVisita.cliente"
                                    outlined
                                    dense
                                    :search-input.sync="search"
                                    :items="items"
                                    :loading="carregando"
                                    item-text="idenome"
                                    item-value="idcliente"
                                    v-else
                                ></v-autocomplete>
                              </div>

                              <v-text-field v-model="formVisita.clienteNome" type="text" required outlined dense style="text-transform: uppercase"
                                            label="Nome Cliente" v-if="buscaPersonalizada.tipo === 'NC'" maxlength="100" class="my-2 pt-0 pb-0"></v-text-field>

                              <div class="text-body-1 font-weight-bold" v-if="buscaPersonalizada.tipo === 'NC'">CPF</div>
                              <v-text-field
                                  class="mb-n3"
                                  ref="CPF"
                                  v-model="formVisita.cpfcnpj"
                                  v-mask="'###.###.###-##'"
                                  outlined
                                  dense
                                  required
                                  v-if="buscaPersonalizada.tipo === 'NC'"
                              ></v-text-field>

                              <v-text-field v-model="formVisita.clienteFone" type="text" required outlined
                                            dense label="Telefone Cliente" @input="formatarTelefone"
                                            maxlength="15"></v-text-field>
                              <div class="text-body-1 font-weight-bold">Profissional</div>
                              <v-select v-model="formVisita.idTipoProfissional" :items="tipoProfissional" required outlined dense
                                        item-text="PROFISSIONAL" item-value="ID"></v-select>
                              <v-text-field v-model="formVisita.mestreObra" type="text" required outlined
                                            dense label="Nome do Profissional"
                                            maxlength="100"></v-text-field>
                              <v-text-field v-model="formVisita.mestreFone" type="text" required outlined
                                            dense label="Telefone do Profissional" @input="formatarTelefone3"
                                            maxlength="15"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="text-right">
                              <v-btn
                                  :disabled="verificaStep1()"
                                  color="primary"
                                  small
                                  @click="step = 2"
                              >Próximo
                              </v-btn>
                            </v-col>
                          </v-row>

                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                            class="px-0 py-1 font-weight-bold"
                        >Dados da obra</v-stepper-step>
                        <v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
                          <v-row>
                            <v-col class="py-3" cols="12">
                              <fieldset class="px-4 pb-3">
                                <legend>Endereço</legend>
                                <v-row class="py-3">
                                  <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                                    <div class="text-body-1 font-weight-bold">Rua</div>
                                    <v-text-field class="mb-n3" v-model="formVisita.enderecoRua" outlined dense required maxlength="100"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="py-0">
                                    <div class="text-body-1 font-weight-bold">Bairro</div>
                                    <v-text-field class="mb-n3" v-model="formVisita.enderecoBairro" outlined dense required maxlength="100"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
                                    <div class="text-body-1 font-weight-bold">Número</div>
                                    <v-text-field class="mb-n3" v-model="formVisita.enderecoNumero" outlined dense required maxlength="6"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                                    <div class="text-body-1 font-weight-bold">Cidade</div>
                                    <v-autocomplete
                                        no-filter
                                        v-model="formVisita.enderecoCidade"
                                        outlined
                                        dense
                                        :search-input.sync="searchCidade2"
                                        :items="itemsCidades2"
                                        :loading="carregando3"
                                        item-text="cidade"
                                        item-value="idcidade"
                                    ></v-autocomplete>

                                  </v-col>

                                </v-row>
                              </fieldset>
                              <fieldset class="px-4 pb-3">
                                <legend>Tipo de obra</legend>
                                <v-row class="py-4">
                                  <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                                    <v-radio-group v-model="formVisita.tipoObra" row>
                                      <v-radio
                                          label="Casa"
                                          color="red darken-3"
                                          value="1"
                                      />
                                      <v-radio
                                          label="Sobrado"
                                          color="red darken-3"
                                          value="2"
                                      />
                                      <v-radio
                                          label="Comercial"
                                          color="red darken-3"
                                          value="3"
                                      />
                                      <v-radio
                                          label="Industrial"
                                          color="red darken-3"
                                          value="4"
                                      />
                                      <v-radio
                                          label="Reforma"
                                          color="red darken-3"
                                          value="5"
                                      />
                                    </v-radio-group>
                                  </v-col>
                                </v-row>
                              </fieldset>
                            </v-col>
                            <v-col cols="12" class="text-right">
                              <v-btn
                                  color="primary"
                                  small
                                  plain
                                  @click="step = 1"
                              >Voltar</v-btn>
                              <v-btn
                                  :disabled="verificaStep2()"
                                  color="primary"
                                  small
                                  @click="step = 3"
                              >Próximo</v-btn>
                            </v-col>
                          </v-row>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                            class="px-0 py-1 font-weight-bold"
                        >Etapa da Obra</v-stepper-step>
                        <v-stepper-content step="3" class="px-2 mx-0 font-weight-bold">
                          <v-row>
                            <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-4">
                              <div class="text-body-1 font-weight-bold">Selecione a etapa</div>
                              <v-select v-model="formVisita.etapasObra" :items="etapasObra" required outlined dense
                                        item-text="ETAPA" item-value="ID"></v-select>


                              <div class="text-body-1 font-weight-bold pb-0" v-if="formVisita.etapasObra != null">Status</div>
                              <v-radio-group v-model="formVisita.statusEtapa" row class="ma-0" v-if="formVisita.etapasObra != null">
                                <v-radio
                                    label="Vendendo"
                                    color="red darken-3"
                                    value="1"
                                    class="py-1"
                                />
                                <v-radio
                                    label="Vendido"
                                    color="red darken-3"
                                    value="3"
                                    class="py-1"
                                />
                                <v-radio
                                    label="Não vendido"
                                    color="red darken-3"
                                    value="4"
                                    class="py-1"
                                />
                              </v-radio-group>
                              <v-col cols="12" md="4" v-if="formVisita.etapasObra != null" class="pl-0 pt-0 py-0">
                                <div class="text-body-1 font-weight-bold pb-0 pt-2">Data de Previsão de conclusão etapa</div>
                                <InputDatePicker :disabled="carregando" v-model="formVisita.dataConclusao"
                                                 :outlined="true" :dense="true"/>
                              </v-col>
                              <v-col cols="12" v-if="formVisita.etapasObra != null" class="pl-0 pt-0 py-0">
                                <v-textarea
                                    class="pt-4 pb-0"
                                    filled
                                    auto-grow
                                    v-model="formVisita.obsObra"
                                    label="Observação"
                                    maxlength="100"
                                ></v-textarea>
                              </v-col>
                                <!--
                                <v-col cols="12" md="4" v-if="formVisita.etapasObra != null" class="pl-0 pt-0 py-0">
                                  <div class="text-body-1 font-weight-bold pb-0 pt-2">Foto Obra</div>
                                  <v-btn
                                      color="primary"
                                      small
                                      class="mb-1"
                                      elevation="0"
                                  >
                                    <v-icon color="white">mdi-camera-enhance</v-icon>
                                  </v-btn>
                                </v-col>
                                -->
<!--                              <v-col cols="12" v-if="formVisita.etapasObra != null" class=" pt-0 py-0">-->
<!--                                <button v-if="!cameraActive" @click="startCamera">Abrir Câmera</button>-->

<!--                                <div v-if="cameraActive">-->
<!--                                  <video ref="video" autoplay playsinline></video>-->
<!--                                  <button @click="capturePhoto">Tirar Foto</button>-->
<!--                                  <button @click="switchCamera">Trocar Câmera</button>-->
<!--                                  <button @click="stopCamera">Fechar Câmera</button>-->

<!--                                  <canvas ref="canvas" style="display: none"></canvas>-->
<!--                                  <img v-if="photo" :src="photo" alt="Foto Capturada" />-->
<!--                                </div>-->
<!--                              </v-col>-->

                            </v-col>
                            <v-col cols="12">
                              <v-btn small text block color="primary" class="mb-1" @click="step = 2">Voltar</v-btn>
                              <v-btn
                                  :disabled="verificaVisita() || carregando"
                                  color="primary"
                                  block
                                  small
                                  class="mb-1"
                                  elevation="0"
                                  @click="enviarForm()"
                              >Cadastrar Visita</v-btn>
                            </v-col>
                          </v-row>
                        </v-stepper-content>

                      </v-stepper>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-card>
                    <v-card-title>Atualização de Visita</v-card-title>
                    <v-card-text>
                      <v-stepper v-model="stepAtt" vertical flat>
                        <v-stepper-step
                            :complete="stepAtt > 1"
                            step="1"
                            class="px-0 py-1 font-weight-bold"
                            editable
                        >Dados do cliente</v-stepper-step>
                        <v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
                          <v-row align="end">
                            <v-col cols="12" md="12" class="py-2 pb-xs-0 pb-0">
                              <div class="text-body-1 font-weight-bold">Obra a visitar</div>
                              <v-select v-model="visitaSelecionada" :items="clientesVisitados" required outlined dense
                                        item-text="NOME" item-value="ID" @change="atualizaDados()"></v-select>
                            </v-col>
                            <v-col cols="12" md="12" class="py-0 pb-xs-0" v-show="enviado">
                              <div class="text-body-1 font-weight-bold">Cliente:</div>
                              <div class="text-body-1">{{formVisitaExistente.clienteNome}}</div>
                              <div class="text-body-1 font-weight-bold">Telefone:</div>
                              <div class="text-body-1">{{formVisitaExistente.clienteFone}}</div>
                              <template v-if="formVisitaExistente.mestreObra != ''">
                                <div class="text-body-1 font-weight-bold">Profissional:</div>
                                <div class="text-body-1">{{formVisitaExistente.mestreObra}}</div>
                              </template>
                              <template v-if="formVisitaExistente.mestreObra != ''">
                                <div class="text-body-1 font-weight-bold" >Telefone profissional:</div>
                                <div class="text-body-1">{{formVisitaExistente.mestreFone}}</div>
                              </template>
                              <div class="text-body-1 font-weight-bold">Tipo de Obra:</div>
                              <div class="text-body-1">{{formVisitaExistente.tipo}}</div>
                              <div class="text-body-1 font-weight-bold">Endereço:</div>
                              <div class="text-body-1">{{formVisitaExistente.enderecoRua}}, {{formVisitaExistente.enderecoBairro}},
                              {{formVisitaExistente.enderecoNumero}} - {{formVisitaExistente.enderecoCidade}}</div>
                            </v-col>
                            <v-col cols="12" class="text-right">
                              <v-btn
                                  :disabled="verificaStepAtt1()"
                                  color="primary"
                                  small
                                  @click="stepAtt = 2"
                              >Próximo
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="stepAtt > 2"
                            step="2"
                            class="px-0 py-1 font-weight-bold"
                        >Etapa da Obra</v-stepper-step>
                        <v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
                          <v-row>
                            <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-4">
                              <div class="text-body-1 font-weight-bold">Selecione a etapa</div>
                              <v-select v-model="formVisitaExistente.etapasObra" :items="etapasObraAtt" required outlined dense
                                        item-text="ETAPAS" item-value="IDCONTROLE"></v-select>

                              <!--
                              <v-card v-if="formVisitaExistente.etapasObra != null" class="primary white--text" elevation="0"
                                      style="border: 1px solid #023449">
                                <v-card-title class="pa-2 text-center align-center justify-center">
                                  Grupos de produtos a oferecer
                                </v-card-title>
                                <v-card-text class="card-itens-oferecer pa-0">
                                  <p class="text-body-1 font-weight-bold pt-4 pl-4 pb-4 ma-0">{{ formVisitaExistente.itensOferecer.join(", ") }}</p>
                                </v-card-text>
                              </v-card>
                              -->


                              <v-card v-if="visitaSelecionada != null" class="primary white--text" elevation="0"
                                      style="border: 1px solid #023449">
                                <v-card-title class="pa-2 text-center align-center justify-center">
                                  Etapas da Obra
                                </v-card-title>
                                <v-card-text class="card-itens-oferecer pa-0">
                                  <v-simple-table>
                                    <thead>
                                      <tr>
                                        <th style="height: 8px; font-size: 14px">
                                          Etapa da Obra
                                        </th>
                                        <th style="height: 8px; font-size: 14px">
                                          Status Etapa
                                        </th>
                                        <th style="height: 8px; font-size: 14px">
                                          Data Previsão Conclusão
                                        </th>
                                        <th style="height: 8px; font-size: 14px">
                                          Status Visita
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(u, i) in etapasCliente" :key="i">
                                        <td class=" text-left font-weight-bold" nowrap style="height: 2px; font-size: 12px">
                                          {{u.ETAPAS}}
                                        </td>
                                        <td class=" text-left font-weight-medium" nowrap style="height: 2px; font-size: 12px">
                                          {{u.STATUS}}
                                        </td>
                                        <td class=" text-left font-weight-bold" nowrap style="height: 2px; font-size: 12px">
                                          {{u.DTCONCLUSAO}}
                                        </td>
                                        <td class="text-left font-weight-bold" nowrap style="height: 2px; font-size: 12px">
                                          {{formataControle(u.CONTROLEVISITA)}}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </v-card-text>
                              </v-card>


                              <div class="text-body-1 font-weight-bold pb-0 pt-6" v-if="formVisitaExistente.etapasObra != null">Status</div>
                              <v-radio-group v-model="formVisitaExistente.statusEtapa" row class="ma-0" v-if="formVisitaExistente.etapasObra != null">
                                <v-radio
                                    label="Vendendo"
                                    color="red darken-3"
                                    value="1"
                                    class="py-1"
                                />
                                <v-radio
                                    label="Vendido"
                                    color="red darken-3"
                                    value="3"
                                    class="py-1"
                                />
                                <v-radio
                                    label="Não vendido"
                                    color="red darken-3"
                                    value="4"
                                    class="py-1"
                                />
                              </v-radio-group>
                              <v-col cols="12" md="4" v-if="formVisitaExistente.etapasObra != null" class="pl-0 pt-0 py-0">
                                <div class="text-body-1 font-weight-bold pb-0 pt-2">Data de Previsão de conclusão etapa</div>
                                <InputDatePicker :disabled="carregando" v-model="formVisitaExistente.dataConclusao"
                                                 :outlined="true" :dense="true" @change="verificaDataConclusaoAtt()"/>
                              </v-col>
                            </v-col>
                            <v-col cols="12" v-if="formVisitaExistente.etapasObra != null" class="pt-0 py-0">
                              <v-textarea
                                  class="pt-4 pb-0"
                                  filled
                                  auto-grow
                                  v-model="formVisitaExistente.obsObra"
                                  label="Observação"
                                  maxlength="100"
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12">
                              <v-btn small text block color="primary" class="mb-1" @click="stepAtt = 1">Voltar</v-btn>
                              <v-btn
                                  :disabled="verificaAttVisita() || carregando"
                                  color="primary"
                                  block
                                  small
                                  class="mb-1"
                                  elevation="0"
                                  @click="enviarNovaEtapa()"
                              >Cadastrar Nova Etapa</v-btn>
                            </v-col>
                          </v-row>
                        </v-stepper-content>

                      </v-stepper>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>


        <v-col cols="12" md="12">
          <v-card class="primary">
            <v-card color="white">
              <v-card-title>
                <v-col class="d-none d-md-block">
                  <span>Minhas Visitas</span>
                </v-col>
                <v-col cols="12" md="12" v-if="isMobile" class="d-flex">
                  <span class="text-center align-center">Minhas Visitas</span>
                </v-col>
                <v-spacer />

                <v-col class="d-none d-md-block">
                  <InputDatePicker :disabled="carregando" v-model="buscaPersonalizada.dtini"
                                   label="Data inicial" :outlined="true" :dense="true"/>
                </v-col>
                <v-col class="d-none d-md-block">
                  <InputDatePicker :disabled="carregando" v-model="buscaPersonalizada.dtfim" label="Data final"
                                   :outlined="true" :dense="true" />
                </v-col>
                <v-col cols="12" md="12" v-if="isMobile">
                  <InputDatePicker :disabled="carregando" v-model="buscaPersonalizada.dtini"
                                   label="Data inicial" :outlined="true" :dense="true"/>
                </v-col>
                <v-col cols="12" md="12" v-if="isMobile">
                  <InputDatePicker :disabled="carregando" v-model="buscaPersonalizada.dtfim" label="Data final"
                                   :outlined="true" :dense="true" />
                </v-col>
                <v-btn :disabled="carregando" class="ml-2 mt-1 d-none d-md-block" color="primary" elevation="0"
                       fab x-small @click="listaVisitas()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-col cols="12" v-if="isMobile" class="fixed-bottom">
                  <v-btn :disabled="carregando" block  color="primary" elevation="0" @click="listaVisitas()">
                    Filtrar
                  </v-btn>
                </v-col>

              </v-card-title>

            </v-card>

            <v-card-text>
              <v-data-table v-if="visitas.length > 0" :headers="[
                        { text: 'IdObra', value: 'id', class: 'text-no-wrap', sortable: false},
                        { text: 'Vendedor', value: 'vendedor', class: 'text-no-wrap', sortable: false },
                        { text: 'Cliente', value: 'cliente', class: 'text-no-wrap', sortable: false },
                        { text: 'Data Visita', value: 'datavisita', class: 'text-no-wrap', sortable: false },
                        { text: 'Fase da Obra', value: 'etapaobra', class: 'text-no-wrap', sortable: false },
                        { text: 'Data Previsão Conclusão', value: 'dataconclusao', class: 'text-no-wrap', sortable: false },
                        { text: 'Editar', value: 'botaoEditaVisita', class: 'text-no-wrap', align: 'center', sortable: false },
                        { text: 'Excluir', value: 'botaoDeletaVisita', class: 'text-no-wrap', align: 'center', sortable: false },]"
                            :items="visitas"
                            item-key="id" class="elevation-0" :items-per-page="50" mobile-breakpoint="0" hide-default-footer>
                <template v-slot:item.vendedor="{ item }">
                  <span class="text-truncate pr-0">{{ item.vendedor }}</span>
                </template>

                <template v-slot:item.cliente="{ item }">
                  <span class="pr-0">{{ item.cliente }}</span>
                </template>

                <template v-slot:item.datavisita="{ item }">
                  <span>{{ item.datavisita }}</span>
                </template>

                <template v-slot:item.etapaobra="{ item }">
                  {{ item.etapaobra }}
                </template>

                <template v-slot:item.botaoEditaVisita="{ item }">
                  <v-icon class="ml-2" @click="getDadosEditar(item)" color="primary"
                          size="20">mdi-pencil</v-icon>
                </template>

                <template v-slot:item.botaoDeletaVisita="{ item }">
                  <v-icon class="ml-2" @click="getDelete(item)" color="red darken-3"
                          size="20">mdi-trash-can-outline</v-icon>
                </template>
              </v-data-table>
              <span v-else class="white--text align-center">Nenhuma informação encontrada</span>

            </v-card-text>
          </v-card>
        </v-col>

      </v-row>


    </v-container>
</template>


<script>
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import axios from "axios";
import CardDialog from "@/Components/Widgets/CardDialog.vue";
export default {
    name: "FormularioControleVisitas",
    components: { CardDialog, InputDatePicker },
    data: () => ({
        editar: null,
        deletar: null,
        dialog: false,
        step: 1,
        stepAtt: 1,
        stepEdit:1,
        tab:null,
        dialogErro: {
          status: false,
          title: "",
          msg: "",
          icon: "",
          iconColor: "",
        },
        dialogEdita: false,
        dialogConfirma: false,
        tipoCliente: false,


        carregando: false,
        carregando2: false,
        carregando3: false,




        estaDesabilitado: false,
        etapasObra: [],
        tipoProfissional: [],
        etapasObraAtt: [],
        formVisita: {
          cliente: null,
          clienteNome: '',
          cpfcnpj: '',
          clienteFone: null,
          mestreObra: null,
          mestreFone: null,
          enderecoRua:'',
          enderecoBairro:'',
          enderecoNumero:'',
          enderecoCidade:'',
          enderecoIdCidade: null,
          tipoObra: null,
          tipoObraOutro: '',
          dataVisita: '',
          etapasObra: null,
          itensOferecer: ['Cimento', 'Ferragens', 'Tijolo', 'Areia Grossa'],
          statusEtapa: null,
          dataConclusao: null,
          obsObra: '',
          idTipoProfissional: null
        },
        formVisitaExistente: {
          idObra: null,
          cliente: null,
          clienteNome: '',
          cpfcnpj: '',
          clienteFone: null,
          mestreObra: null,
          mestreFone: null,
          enderecoRua:'',
          enderecoBairro:'',
          enderecoNumero:'',
          enderecoCidade:'',
          enderecoIdCidade: null,
          tipoObra: null,
          tipoObraOutro: '',
          dataVisita: '',
          etapasObra: null,
          itensOferecer: ['Cimento', 'Ferragens', 'Tijolo', 'Areia Grossa'],
          statusEtapa: null,
          dataConclusao: null,
          obsObra: '',
          idTipoProfissional: null
        },
        visitaSelecionada: null,
        enviado: false,
        clientesVisitados: [],
        etapasCliente:[],


        timestamp: null,
        timestamp2: null,
        searchCidade: null,
        searchCidade2: null,
        search: null,
        search2: null,
        search3: null,
        search4: null,
        sheet: false,
        searchClienteGestor: null,

        buscaPersonalizada:{
          busca: '',
          dtini: `${new Date().getFullYear()}-${
              (new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 1,
                  0
              ).getMonth() +
              1 <
              10
                  ? "0"
                  : "") +
              (new Date().getMonth() + 1)
          }-01`,
          dtfim: `${new Date(new Date())
              .toLocaleDateString("pt-BR")
              .split("/")
              .reverse()
              .join("-")}`,
          tipo: 'C',
          tipoEdit: 'C',
        },
        aguardarBusca: "",
        clientes: [],
        clientesGestor: [],
        dadosClientesGestor: {
            lista: []
        },
        cidades: [],
        visitas: [],
        editaForm:{
          id: null,
          cliente: null,
          clienteNome: '',
          cpfcnpj: '',
          clienteFone: null,
          mestreObra: null,
          mestreFone: null,
          enderecoRua:'',
          enderecoBairro:'',
          enderecoNumero:'',
          enderecoCidade:'',
          enderecoIdCidade: null,
          tipoObra: null,
          tipoObraOutro: '',
          dataVisita: '',
          etapasObra: null,
          itensOferecer: ['Cimento', 'Ferragens', 'Tijolo', 'Areia Grossa'],
          statusEtapa: null,
          dataConclusao: null,
          obsObra: '',
          ip: '',
          latitude: '',
          longitude: '',
          idTipoProfissional: null
        },
        deletaForm:{},
        ip: null,
        lat: null,
        long: null,
        cidadeLimit: null,
        aguardarBuscaCidade: null,

        //// variaveis para camera
        stream: null,
        photo: null,
        useFrontCamera: true,
        cameraActive: false, // Inicialmente a câmera está desativada

    }),
    computed: {
        ...mapState(["backendUrl"]),
      itemsGestor() {
        if (this.clientesGestor == null) return;

        this.clientesGestor.forEach((cliente) => {
          if (cliente.NOME.length > 2) {
            cliente.NOME =
                cliente.NOME.slice(0, this.nomeLimit);
          }
        });

        return this.clientesGestor;
      },
      itemsGestor2() {
        if (this.clientesGestor == null) return;

        this.clientesGestor.forEach((cliente) => {
          if (cliente.nome.length > this.nomeLimit) {
            cliente.nome =
                cliente.nome.slice(0, this.nomeLimit) + "...";
          }
        });

        return this.clientes;
      },
      items() {
        if (this.clientes == null) return;

        this.clientes.forEach((cliente) => {
          if (cliente.nome.length > this.nomeLimit) {
            cliente.nome =
                cliente.nome.slice(0, this.nomeLimit) + "...";
          }
        });

        return this.clientes;
      },
      items2() {
        if (this.clientes == null) return;

        this.clientes.forEach((cliente) => {
          if (cliente.nome.length > this.nomeLimit) {
            cliente.nome =
                cliente.nome.slice(0, this.nomeLimit) + "...";
          }
        });

        return this.clientes;
      },
      itemsCidades() {
        if (this.cidades == null) return;
        this.cidades.forEach((cidade1) => {
          if (cidade1.cidade.length > this.nomeLimit) {
            cidade1.cidade =
                cidade1.cidade.slice(0, this.nomeLimit) + "...";
          }
        });
        return this.cidades;
      },
      itemsCidades2() {
        if (this.cidades == null) return;
        this.cidades.forEach((cidade1) => {
          if (cidade1.cidade.length > this.nomeLimit) {
            cidade1.cidade =
                cidade1.cidade.slice(0, this.nomeLimit) + "...";
          }
        });
        return this.cidades;
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown; // Para sm e xs
      },
      messages () {
        return this.tipoCliente ? ['CISS'] : undefined
      },


    },
    methods: {
        atualizaDados(){
          this.getDadosAtualizar();
          this.listaEtapasVisita();
          this.listaEtapasAtt();
        },
        formataControle(item){
            if(item === 'TRUE'){
              return 'VISITADO'
            }else if(item === 'FALSE'){
              return 'NÃO VISITADO'
            }
        },
        verificaDataConclusao() {
          let dataHoje = new Date();
          dataHoje.setHours(0, 0, 0, 0); // Zerar horas para garantir comparação apenas por data

          // Converter corretamente a data para evitar deslocamento de fuso horário
          let dataLancada = new Date(this.formVisita.dataConclusao + "T00:00:00");

          if (isNaN(dataLancada.getTime())) {
            console.error("Data inválida:", this.formVisita.dataConclusao);
            return;
          }

          dataLancada.setHours(0, 0, 0, 0); // Zerar horas para comparação exata

          if (dataLancada < dataHoje) {
            this.dialogErro = {
              title: "Data inválida!",
              msg: "Não é possível lançar uma previsão com a data inferior à data atual!",
              icon: "mdi-alert-circle-outline",
              iconColor: "red",
              status: true
            };

            this.formVisita.dataConclusao = ''; // Opcional: limpar campo
          }
        },
        verificaDataConclusaoAtt() {
        let dataHoje = new Date();
        dataHoje.setHours(0, 0, 0, 0); // Zerar horas para garantir comparação apenas por data

        // Converter corretamente a data para evitar deslocamento de fuso horário
        let dataLancada = new Date(this.formVisitaExistente.dataConclusao + "T00:00:00");

        if (isNaN(dataLancada.getTime())) {
          console.error("Data inválida:", this.formVisitaExistente.dataConclusao);
          return;
        }

        dataLancada.setHours(0, 0, 0, 0); // Zerar horas para comparação exata

        if (dataLancada < dataHoje) {
          this.dialogErro = {
            title: "Data inválida!",
            msg: "Não é possível lançar uma previsão com a data inferior à data atual!",
            icon: "mdi-alert-circle-outline",
            iconColor: "red",
            status: true
          };

          this.formVisitaExistente.dataConclusao = ''; // Opcional: limpar campo
        }
      },
        verificaDataConclusaoEdit() {
        let dataHoje = new Date();
        dataHoje.setHours(0, 0, 0, 0); // Zerar horas para garantir comparação apenas por data

        // Converter corretamente a data para evitar deslocamento de fuso horário
        let dataLancada = new Date(this.editaForm.dataConclusao + "T00:00:00");

        if (isNaN(dataLancada.getTime())) {
          console.error("Data inválida:", this.editaForm.dataConclusao);
          return;
        }

        dataLancada.setHours(0, 0, 0, 0); // Zerar horas para comparação exata

        if (dataLancada < dataHoje) {
          this.dialogErro = {
            title: "Data inválida!",
            msg: "Não é possível lançar uma previsão com a data inferior à data atual!",
            icon: "mdi-alert-circle-outline",
            iconColor: "red",
            status: true
          };

          this.editaForm.dataConclusao = ''; // Opcional: limpar campo
        }
      },



        verificaStep1() {
          const telefoneClienteValido = this.formVisita.clienteFone?.toString().trim().length >= 10;
          const telefoneClienteNovoValido = this.formVisita.clienteFone?.toString().trim().length >= 14;
          const telefoneMestreValido = this.formVisita.mestreFone?.toString().trim().length >= 14;
          const tipoProfissionalValido = this.formVisita.idTipoProfissional?.toString().trim().length > 0;

          const clientePreenchido = this.formVisita.cliente?.toString().trim() && telefoneClienteValido;

          const clienteNovoPreenchido = this.formVisita.clienteNome?.toString().trim() && this.formVisita.cpfcnpj?.toString().trim() && telefoneClienteNovoValido;

          // Se o mestre de obra for preenchido, telefone e tipo profissional também devem ser preenchidos
          const mestrePreenchido =
              this.formVisita.mestreObra?.toString().trim() &&
              telefoneMestreValido &&
              tipoProfissionalValido;

          // Bloqueia se o mestre de obra for preenchido sem telefone ou tipo profissional
          if (this.formVisita.mestreObra?.toString().trim() && (!telefoneMestreValido || !tipoProfissionalValido)) {
            return true;
          }

          return !(clientePreenchido || mestrePreenchido || clienteNovoPreenchido);
        },

        verificaStep2() {
          return (
              !this.formVisita.enderecoRua?.toString().trim() ||
              !this.formVisita.enderecoBairro?.toString().trim() ||
              !this.formVisita.enderecoNumero?.toString().trim() ||
              !this.formVisita.enderecoCidade?.toString().trim() ||
              !this.formVisita.tipoObra?.toString().trim()
          );
        },
        verificaEditStep1() {
          const telefoneClienteValido = this.editaForm.clienteFone?.toString().trim().length >= 10;
          const telefoneClienteNovoValido = this.editaForm.clienteFone?.toString().trim().length >= 14;
          const telefoneMestreValido = this.editaForm.mestreFone?.toString().trim().length >= 14;
          const tipoProfissionalValido = this.editaForm.idTipoProfissional?.toString().trim().length > 0;

          const clientePreenchido = this.editaForm.cliente?.toString().trim() && telefoneClienteValido;

          const clienteNovoPreenchido = this.editaForm.clienteNome?.toString().trim() && this.editaForm.cpfcnpj?.toString().trim() && telefoneClienteNovoValido;

          // Se o mestre de obra for preenchido, telefone e tipo profissional também devem ser preenchidos
          const mestrePreenchido =
              this.editaForm.mestreObra?.toString().trim() &&
              telefoneMestreValido &&
              tipoProfissionalValido;

          // Bloqueia se o mestre de obra for preenchido sem telefone ou tipo profissional
          if (this.editaForm.mestreObra?.toString().trim() && (!telefoneMestreValido || !tipoProfissionalValido)) {
            return true;
          }

          return !(clientePreenchido || mestrePreenchido || clienteNovoPreenchido);
        },
        verificaEditStep2() {
          return(!this.editaForm.enderecoRua?.toString().trim() ||
          !this.editaForm.enderecoBairro?.toString().trim() ||
          !this.editaForm.enderecoNumero?.toString().trim() ||
          !this.editaForm.enderecoCidade?.toString().trim())
        },
        verificaVisitaEdit(){
          const telefoneClienteValido = this.editaForm.clienteFone?.toString().trim().length >= 10;
          const telefoneClienteNovoValido = this.editaForm.clienteFone?.toString().trim().length >= 14;
          const telefoneMestreValido = this.editaForm.mestreFone?.toString().trim().length >= 14;

          const clientePreenchido = this.editaForm.cliente?.toString().trim() && telefoneClienteValido;
          const mestrePreenchido = this.editaForm.mestreObra?.toString().trim() && telefoneMestreValido;
          const clienteNovoPreenchido = this.editaForm.clienteNome?.toString().trim() && this.editaForm.cpfcnpj?.toString().trim() && telefoneClienteNovoValido;

          return (
              !(clientePreenchido || mestrePreenchido || clienteNovoPreenchido) ||
              !this.editaForm.enderecoRua?.toString().trim() ||
              !this.editaForm.enderecoBairro?.toString().trim() ||
              !this.editaForm.enderecoNumero?.toString().trim() ||
              !this.editaForm.enderecoCidade?.toString().trim() ||
              !this.editaForm.etapasObra?.toString().trim() ||
              !this.editaForm.statusEtapa?.toString().trim() ||
              !this.editaForm.dataConclusao?.toString().trim()
          );
        },
        verificaVisita() {
          const clientePreenchido = this.formVisita.cliente?.toString().trim() && this.formVisita.clienteFone?.toString().trim();
          const mestrePreenchido = this.formVisita.mestreObra?.toString().trim() && this.formVisita.mestreFone?.toString().trim();
          const clienteNovoPreenchido = this.formVisita.clienteNome?.toString().trim() && this.formVisita.cpfcnpj?.toString().trim() && this.formVisita.clienteFone?.toString().trim();

          // Verifica se pelo menos um dos pares (cliente ou mestre) está preenchido
          const peloMenosUmPreenchido = clientePreenchido || mestrePreenchido || clienteNovoPreenchido;

          if (!peloMenosUmPreenchido) return true; // Impede o cadastro se ambos estiverem vazios

          return (
              !this.formVisita.enderecoRua?.toString().trim() ||
              !this.formVisita.enderecoBairro?.toString().trim() ||
              !this.formVisita.enderecoNumero?.toString().trim() ||
              !this.formVisita.enderecoCidade?.toString().trim() ||
              !this.formVisita.tipoObra?.toString().trim() ||
              !this.formVisita.etapasObra?.toString().trim() ||
              !this.formVisita.statusEtapa?.toString().trim() ||
              !this.formVisita.dataConclusao?.toString().trim()
          );
        },
        verificaStepAtt1(){
          return !this.formVisitaExistente.idObra?.toString().trim()
        },
        verificaAttVisita(){
          return (
              !this.formVisitaExistente.etapasObra?.toString().trim() ||
              !this.formVisitaExistente.statusEtapa?.toString().trim() ||
              !this.formVisitaExistente.dataConclusao?.toString().trim()
          );
        },
        formatarTelefone() {
          let numero = this.formVisita.clienteFone.replace(/\D/g, ''); // Remove tudo que não for número

          // Limita o número a no máximo 11 dígitos (para suportar telefones com 9 dígitos no Brasil)
          numero = numero.slice(0, 11);

          if (numero.length > 10) {
            this.formVisita.clienteFone = numero.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
          } else {
            this.formVisita.clienteFone = numero.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
          }
        },
        formatarTelefone2() {
          let numero2 = this.editaForm.clienteFone.replace(/\D/g, ''); // Remove tudo que não for número

          // Limita o número a no máximo 11 dígitos (para suportar telefones com 9 dígitos no Brasil)
          numero2 = numero2.slice(0, 11);

          if (numero2.length > 10) {
            this.editaForm.clienteFone = numero2.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
          } else {
            this.editaForm.clienteFone = numero2.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
          }
        },
        formatarTelefone3() {
          let numero2 = this.formVisita.mestreFone.replace(/\D/g, ''); // Remove tudo que não for número

          // Limita o número a no máximo 11 dígitos (para suportar telefones com 9 dígitos no Brasil)
          numero2 = numero2.slice(0, 11);

          if (numero2.length > 10) {
            this.formVisita.mestreFone = numero2.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
          } else {
            this.formVisita.mestreFone = numero2.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
          }
        },
        formatarTelefone4() {
          let numero2 = this.editaForm.mestreFone.replace(/\D/g, ''); // Remove tudo que não for número

          // Limita o número a no máximo 11 dígitos (para suportar telefones com 9 dígitos no Brasil)
          numero2 = numero2.slice(0, 11);

          if (numero2.length > 10) {
            this.editaForm.mestreFone = numero2.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
          } else {
            this.editaForm.mestreFone = numero2.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
          }
        },
        listaEtapas(){
          this.etapasObra = [];
          this.carregando = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visitaetapa`, {})
              .then((res) => {
                console.log(res.data)
                this.etapasObra = JSON.parse(res.data) || [];
                this.carregando = false;
              })
              .catch(() => {
                this.carregando = false;
              });
        },
        listaTipoProfissional(){
          this.tipoProfissional = [];
          this.carregando = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visitatipoprofissional`, {})
              .then((res) => {
                this.tipoProfissional = JSON.parse(res.data.tipoprofissional) || [];
                this.carregando = false;
              })
              .catch(() => {
                this.carregando = false;
              });
        },
        listaEtapasAtt(){
          this.etapasObraAtt = [];
          this.carregando = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visitavendedorvalido`, {
                id: this.visitaSelecionada,
              })
              .then((res) => {
                console.log(res.data)
                this.etapasObraAtt = res.data.visitavendedorvalido || [];
                this.carregando = false;
              })
              .catch(() => {
                this.carregando = false;
              });
        },
        enviarForm() {
          this.carregando = true;
            let dataHoje = new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDay()+' '+ new Date().getHours() +':'+ new Date().getMinutes()+':'+ new Date().getSeconds();
          return axios
              .post(`${this.backendUrl}dashboard/controle/visita/inserir`, {
                id: 0,
                idclifor: parseInt(this.formVisita.cliente) || 0,
                cliente: this.formVisita.clienteNome || '',
                cnpjcpf: this.formVisita.cpfcnpj.replace(/\D/g, '') || '0',
                telefone: this.formVisita.clienteFone || '',
                datavisita: dataHoje,
                etapaobra: this.formVisita.etapasObra,
                observacao: (this.formVisita.obsObra || '').toUpperCase(),
                ip: String(this.ip),
                latitude: String(this.lat) || '1',
                longitude: String(this.long) || '2',
                flagativo: true,
                idtipo: parseInt(this.formVisita.tipoObra),
                idstatus: parseInt(this.formVisita.statusEtapa),
                dtconclusaoetapa: this.formVisita.dataConclusao,
                endereco: this.formVisita.enderecoRua,
                bairro: this.formVisita.enderecoBairro,
                numero: String(this.formVisita.enderecoNumero),
                idcidade: this.formVisita.enderecoIdCidade || this.formVisita.enderecoCidade,
                nomemestre: (this.formVisita.mestreObra || '').toUpperCase(),
                telefonemestre: this.formVisita.mestreFone || '',
                idtipoprofissional: parseInt(this.formVisita.idTipoProfissional) || 10

              })
              .then(() => {
                this.dialogErro.title = "Sucesso";
                this.dialogErro.msg = "Visita cadastrada com sucesso!";
                this.dialogErro.icon = "mdi-check-circle-outline";
                this.dialogErro.iconColor = "success";
                this.dialogErro.status = true;
                this.carregando = false;
                console.log("Texto enviado:", JSON.stringify(this.formVisita.obsObra)); // Verificar quebras de linha
                this.limpar();
                this.listaVisitas();
                this.listaEtapas();
                this.listaVisitasPendentes();
                this.buscaPersonalizada.tipo = 'C';
                this.step = 1;

              })
              .catch(() => {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Erro ao cadastrar Visita!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                this.carregando = false;
              });
      },
        enviarNovaEtapa(){
          this.carregando = true;
          console.log('dados a enviar', this.formVisitaExistente);
          return axios
              .post(`${this.backendUrl}dashboard/controle/visitaUpdateetapa`, {
                id: this.formVisitaExistente.idObra,
                etapaobra: parseInt(this.formVisitaExistente.etapasObra),
                idstatus: parseInt(this.formVisitaExistente.statusEtapa),
                dtconclusaoetapa: this.formVisitaExistente.dataConclusao,
                observacao: this.formVisitaExistente.obsObra.toUpperCase() || '',
              })
              .then(() => {
                this.dialogErro.title = "Sucesso";
                this.dialogErro.msg = "Visita cadastrada com sucesso!";
                this.dialogErro.icon = "mdi-check-circle-outline";
                this.dialogErro.iconColor = "success";
                this.dialogErro.status = true;
                this.carregando = false;
                this.limpar();
                this.listaVisitas();
                this.listaEtapas();
                this.listaVisitasPendentes();
                this.stepAtt = 1;

              })
              .catch(() => {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Erro ao cadastrar etapa!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                this.carregando = false;
              });
        },
        listaVisitas(){
          this.visitas = [];
          this.carregando = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visita/vendedor/listar`, {
                dtini: this.buscaPersonalizada.dtini,
                dtfim: this.buscaPersonalizada.dtfim
              })
              .then((res) => {
                this.visitas = res.data || [];
                this.carregando = false;
              })
              .catch(() => {
                this.carregando = false;
              });
        },
        listaEtapasVisita(){
          this.etapasCliente = [];
          this.carregando2 = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visitavendedor`, {
                id: this.visitaSelecionada
              })
              .then((res) => {
                console.log(res.data)
                this.etapasCliente = res.data.visitavendedor || [];
                this.carregando2 = false;
              })
              .catch(() => {
                this.carregando2 = false;
              });
        },
        listaVisitasPendentes(){
          this.clientesVisitados = [];
          this.carregando2 = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visitaaberto`, {})
              .then((res) => {
                this.clientesVisitados = JSON.parse(res.data.aberto) || [];
                this.carregando2 = false;
              })
              .catch(() => {
                this.carregando2 = false;
              });
        },
        editaVisita(){
          console.log(this.editaForm)
          this.carregando2 = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visita/inserir`, {
                id: this.editaForm.id,
                idclifor: this.editaForm.cliente || 0,
                cliente: this.editaForm.clienteNome || '',
                cnpjcpf: this.editaForm.cpfcnpj.replace(/\D/g, '') || '0',
                telefone: this.editaForm.clienteFone || '',
                datavisita: new Date().getDate()+' '+ new Date().getHours() +':'+ new Date().getMinutes()+':'+ new Date().getSeconds(),
                etapaobra: this.editaForm.etapasObra,
                observacao:  this.editaForm.obsObra || '',
                ip: String(this.ip),
                latitude: String(this.lat) || '1',
                longitude: String(this.long) || '2',
                flagativo: true,
                endereco: this.editaForm.enderecoRua,
                bairro: this.editaForm.enderecoBairro,
                numero: this.editaForm.enderecoNumero,
                idcidade: this.editaForm.enderecoIdCidade || this.editaForm.enderecoCidade,
                idtipo: parseInt(this.editaForm.tipoObra),
                idstatus: parseInt(this.editaForm.statusEtapa),
                dataconclusao: this.editaForm.dataConclusao,
                nomemestre: this.editaForm.mestreObra.toUpperCase() || '',
                telefonemestre: this.editaForm.mestreFone || '',
                idtipoprofissional: parseInt(this.editaForm.idTipoProfissional) || 4
              })
              .then(() => {
                this.dialogErro.title = "Sucesso";
                this.dialogErro.msg = "Visita editada com sucesso!";
                this.dialogErro.icon = "mdi-check-circle-outline";
                this.dialogErro.iconColor = "success";
                this.dialogErro.status = true;
                this.carregando2 = false;
                this.listaVisitas();
                this.limpar();
                this.dialogEdita = false;
                this.stepEdit = 1;

              })
              .catch(() => {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Erro ao editar Visita!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                this.carregando2 = false;
              });
        },
        deletaVisita(){
          this.carregando2 = true;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visita/inserir`, {
                id: this.deletaForm.id,
                idclifor: this.deletaForm.cliente,
                cliente: this.deletaForm.clienteNome,
                cnpjcpf: this.deletaForm.cpfcnpj.replace(/\D/g, ''),
                telefone: this.deletaForm.clienteFone,
                datavisita: this.deletaForm.datavisita,
                etapaobra: this.deletaForm.etapasObra,
                observacao: this.deletaForm.observacao || '',
                ip: String(this.ip),
                latitude: String(this.lat) || '1',
                longitude: String(this.long) || '2',
                flagativo: false,
                idtipo: parseInt(this.deletaForm.tipoObra),
                idstatus: parseInt(this.deletaForm.statusEtapa),
                dataconclusaoetapa: this.deletaForm.dataConclusao,
                endereco: this.deletaForm.enderecoRua,
                bairro: this.deletaForm.enderecoBairro,
                numero: String(this.deletaForm.enderecoNumero),
                idcidade: this.deletaForm.enderecoIdCidade,
                nomemestre: this.deletaForm.mestreObra || '',
                telefonemestre: this.deletaForm.mestreFone || '',
                idtipoprofissional: parseInt(this.deletaForm.idTipoProfissional) || 4,
              })
              .then(() => {
                this.dialogErro.title = "Sucesso";
                this.dialogErro.msg = "Visita excluída com sucesso!";
                this.dialogErro.icon = "mdi-check-circle-outline";
                this.dialogErro.iconColor = "success";
                this.dialogErro.status = true;
                this.carregando2 = false;
                this.dialogConfirma = false;
                this.listaVisitas();

              })
              .catch(() => {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Erro ao excluir Visita!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                this.carregando2 = false;
                this.dialogConfirma = false;
              });
        },
        getDadosAtualizar(){
          this.enviado = false;
          return axios
              .post(`${this.backendUrl}dashboard/controle/visita/editar`, {
                id: this.visitaSelecionada
              })
              .then((res) => {
                res.data.forEach((dado) => {
                  this.formVisitaExistente = {
                    idObra: dado.id,
                    cliente: dado.idclifor,
                    clienteNome: dado.cliente,
                    cpfcnpj: dado.cnpjcpf,
                    clienteFone: dado.telefone,
                    etapasObra: null,
                    enderecoRua: dado.endereco,
                    enderecoBairro: dado.bairro,
                    enderecoNumero: dado.numero,
                    enderecoCidade: dado.cidade,
                    enderecoIdCidade: dado.idcidade,
                    idTipoObra: String(dado.idtipo),
                    tipo: dado.tipo,
                    mestreObra: dado.nomemestre,
                    mestreFone: dado.telefonemestre,
                    idTipoProfissional: String(dado.idtipoprofissional),
                    obsObra: ''
                  };
                });
                this.enviado = true;
              })
              .catch(() => {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Erro ao buscar dados da Visita!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                this.carregando2 = false;
              });
        },
        getDadosEditar(item){
          return axios
              .post(`${this.backendUrl}dashboard/controle/visita/editar`, {
                id: item.id
              })
              .then((res) => {
                console.log('DADOS PUROS: ', res.data);
                res.data.forEach((dado) => {
                  this.editaForm = {
                    id: dado.id,
                    cliente: dado.idclifor,
                    clienteNome: dado.cliente,
                    cpfcnpj: dado.cnpjcpf,
                    clienteFone: dado.telefone,
                    etapasObra: dado.idetapa,
                    enderecoRua: dado.endereco,
                    enderecoBairro: dado.bairro,
                    enderecoNumero: dado.numero,
                    enderecoCidade: dado.idcidade,
                    enderecoIdCidade: dado.idcidade,
                    tipoObra: String(dado.idtipo),
                    statusEtapa: String(dado.idstatus),
                    dataConclusao: this.formatarData(dado.dataconclusao),
                    mestreObra: dado.nomemestre,
                    mestreFone: dado.telefonemestre,
                    idTipoProfissional: dado.idtipoprofissional || 4,
                    obsObra: dado.observacao.toUpperCase() || '',
                  };
                });
                console.log('FORMATADO: ',this.editaForm);
                if(this.editaForm.cpfcnpj?.toString().trim().length >= 11){
                  this.buscaPersonalizada.tipoEdit = 'NC';
                }else{
                  this.buscaPersonalizada.tipoEdit = 'C';
                }
                this.dialogEdita = true;
                this.carregarClientes2(String(this.editaForm.cliente));
                this.carregarCidades(String(this.editaForm.enderecoCidade));
              })
              .catch(() => {
                this.dialogErro.title = "Erro";
                this.dialogErro.msg = "Erro ao buscar dados da Visita!";
                this.dialogErro.icon = "mdi-alert-circle-outline";
                this.dialogErro.iconColor = "red";
                this.dialogErro.status = true;
                this.carregando2 = false;
              });
        },
        getDadosDeletar(item){
        console.log(item);
        return axios
            .post(`${this.backendUrl}dashboard/controle/visita/editar`, {
              id: item.id
            })
            .then((res) => {
              console.log(res.data);
              res.data.forEach((dado) => {
                this.deletaForm = {
                  id: dado.id,
                  cliente: dado.idclifor,
                  clienteNome: dado.cliente,
                  cpfcnpj: dado.cnpjcpf,
                  clienteFone: dado.telefone,
                  etapasObra: dado.idetapa,
                  enderecoRua: dado.endereco,
                  enderecoBairro: dado.bairro,
                  enderecoNumero: dado.numero,
                  enderecoCidade: dado.cidade,
                  enderecoIdCidade: dado.idcidade,
                  tipoObra: String(dado.idtipo),
                  statusEtapa: String(dado.idstatus),
                  dataConclusao: dado.dataconclusao,
                  mestreObra: dado.nomemestre,
                  mestreFone: dado.telefonemestre,
                  idTipoProfissional: String(dado.idtipoprofissional),
                  obsObra: dado.observacao || ''
                };
              });
              console.log(this.editaForm);
            })
            .catch(() => {
              this.dialogErro.title = "Erro";
              this.dialogErro.msg = "Erro ao buscar dados da Visita!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
              this.carregando2 = false;
            });
      },
        getDelete(item){
          this.deletar = item;
          this.dialogConfirma = true;
          this.getDadosDeletar(item);
        },
        async getIp() {
            try {
                const response = await fetch("https://api64.ipify.org?format=json");
                const data = await response.json();
                this.ip = data.ip;

            } catch (error) {
                console.error("Erro ao capturar IP:", error);
            }
        },
        async getLocalizacaoPorIP() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.lat = position.coords.latitude;
                        this.long = position.coords.longitude;
                        console.log("Localização capturada:", this.lat, this.long);
                    },
                    (error) => {
                        console.error("Erro ao obter localização:", error);
                    }
                );
            } else {
                console.error("Geolocalização não é suportada neste navegador.");
            }
        },
        limpar() {
          this.formVisita.cliente = null;
          this.formVisita.clienteNome = null;
          this.formVisita.cpfcnpj = null;
          this.formVisita.clienteFone = null;
          this.formVisita.enderecoRua = '';
          this.formVisita.enderecoBairro = '';
          this.formVisita.enderecoNumero = '';
          this.formVisita.enderecoCidade = '';
          this.formVisita.tipoObra = null;
          this.formVisita.tipoObraOutro = '';
          this.formVisita.dataVisita = '';
          this.formVisita.etapasObra = null;
          this.formVisita.itensOferecer = ['Cimento', 'Ferragens', 'Tijolo', 'Areia Grossa'];
          this.formVisita.statusEtapa = null;
          this.formVisita.dataConclusao = null;
          this.formVisita.obsObra = '';
          this.formVisita.mestreObra = '';
          this.formVisita.mestreFone = '';
          this.formVisita.idTipoProfissional = null;

          this.visitaSelecionada = null;
          this.enviado = false;
          this.formVisitaExistente.idObra= null;
          this.formVisitaExistente.cliente = null;
          this.formVisitaExistente.cpfcnpj = null;
          this.formVisitaExistente.clienteNome = null;
          this.formVisitaExistente.clienteFone = null;
          this.formVisitaExistente.enderecoRua = '';
          this.formVisitaExistente.enderecoBairro = '';
          this.formVisitaExistente.enderecoNumero = '';
          this.formVisitaExistente.enderecoCidade = '';
          this.formVisitaExistente.tipoObra = null;
          this.formVisitaExistente.tipo = null;
          this.formVisitaExistente.tipoObraOutro = '';
          this.formVisitaExistente.dataVisita = '';
          this.formVisitaExistente.etapasObra = null;
          this.formVisitaExistente.itensOferecer = ['Cimento', 'Ferragens', 'Tijolo', 'Areia Grossa'];
          this.formVisitaExistente.statusEtapa = null;
          this.formVisitaExistente.dataConclusao = null;
          this.formVisitaExistente.obsObra = '';
          this.formVisitaExistente.idTipoProfissional = null;

          this.editaForm.cliente = null;
          this.editaForm.clienteNome = null;
          this.editaForm.cpfcnpj = null;
          this.editaForm.clienteFone = null;
          this.editaForm.enderecoRua = '';
          this.editaForm.enderecoBairro = '';
          this.editaForm.enderecoNumero = '';
          this.editaForm.enderecoCidade = '';
          this.editaForm.tipoObra = null;
          this.editaForm.tipoObraOutro = '';
          this.editaForm.dataVisita = '';
          this.editaForm.etapasObra = null;
          this.editaForm.itensOferecer = ['Cimento', 'Ferragens', 'Tijolo', 'Areia Grossa'];
          this.editaForm.statusEtapa = null;
          this.editaForm.dataConclusao = null;
          this.editaForm.obsObra = '';
          this.editaForm.idTipoProfissional = null;

        },
        limparEditar() {
          this.editaForm = {};
        },
        cancelarEditar(){
          this.dialogEdita = false;
          this.stepEdit = 1;
          this.limparEditar();
        },
        formatarData(data) {
          if (!data) return "";

          const [dia, mes, ano] = data.split("/");
          return `${ano}-${mes}-${dia}`;
        },
        carregarClientesGestor(val) {
          clearTimeout(this.aguardarBusca);

          this.aguardarBusca = setTimeout(() => {
            this.carregando2 = true;
            return axios
                .post(`${this.backendUrl}dashboard/controle/visitaclientelistar`, {
                  busca: val || '',
                })
                .then((res) => {
                  console.log('dados puros: ', res.data)
                  this.dadosClientesGestor.lista = JSON.parse(res.data.NOME);
                  console.log('dados convertidos: ', this.dadosClientesGestor);
                  this.clientesGestor = this.dadosClientesGestor.lista.map((v) => {
                    return {
                      ...v,
                      idenome: `${v.NOME}`
                    };
                  });
                  this.carregando2 = false;
                })
                .catch(() => {
                  this.carregando2 = false;
                });
          }, this.timeLimit);
        },
        carregarClientes(val) {
          clearTimeout(this.aguardarBusca);
          this.aguardarBusca = setTimeout(() => {
            this.carregando2 = true;
            return axios
                .post(`${this.backendUrl}cliente/listar`, {
                  limit: 30,
                  offset: 0,
                  busca: val || null,
                })
                .then((res) => {
                  this.clientes = res.data.lista.map((v) => {
                    return {
                      ...v,
                      idenome: `${v.idcliente} - ${v.nome}`,
                      telefone: `${v.telefoneComercial}`
                    };
                  });
                  this.carregando2 = false;
                })
                .catch(() => {
                  this.carregando2 = false;
                });
          }, this.timeLimit);
        },
      carregarClientes2(val) {
          clearTimeout(this.aguardarBusca);
          this.aguardarBusca = setTimeout(() => {
            this.carregando2 = true;
            return axios
                .post(`${this.backendUrl}cliente/listar`, {
                  limit: 30,
                  offset: 0,
                  busca: val || null,
                })
                .then((res) => {
                  this.clientes = res.data.lista.map((v) => {
                    return {
                      ...v,
                      idenome: `${v.idcliente} - ${v.nome}`,
                    };
                  });
                  this.carregando2 = false;
                })
                .catch(() => {
                  this.carregando2 = false;
                });
          }, this.timeLimit);
        },
        carregarCidades(val) {
          clearTimeout(this.aguardarBuscaCidade);
          this.aguardarBuscaCidade = setTimeout(() => {
            this.carregando3 = true;
            return axios
                .post(`${this.backendUrl}cidade/listar`, {
                  busca: val || null,
                })
                .then((res) => {
                  this.cidades = res.data.map((v) => {
                    return {
                      ...v,
                      selectCidade: `${v.cidade} - ${v.uf}`,
                    };
                  });
                  this.carregando3 = false;
                })
                .catch(() => {
                  this.carregando3 = false;
                });
          }, this.timeLimit);
        },
        carregarCidades2(val) {
          clearTimeout(this.aguardarBuscaCidade);
          this.aguardarBuscaCidade = setTimeout(() => {
            this.carregando3 = true;
            return axios
                .post(`${this.backendUrl}cidade/listar`, {
                  busca: val || null,
                })
                .then((res) => {
                  this.cidades = res.data.map((v) => {
                    return {
                      ...v,
                      selectCidade: `${v.cidade} - ${v.uf}`,
                    };
                  });
                  this.carregando3 = false;
                })
                .catch(() => {
                  this.carregando3 = false;
                });
          }, this.timeLimit);
        },
        async startCamera() {
          if (this.stream) {
            this.stopCamera();
          }

          const constraints = {
            video: { facingMode: this.useFrontCamera ? "user" : "environment" }
          };

          try {
            this.stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.$refs.video.srcObject = this.stream;
            this.cameraActive = true;
          } catch (error) {
            console.error("Erro ao acessar a câmera:", error);
          }
        },
        capturePhoto() {
          const canvas = this.$refs.canvas;
          const video = this.$refs.video;
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          this.photo = canvas.toDataURL("image/png"); // Salva a foto como Base64
        },
        switchCamera() {
          this.useFrontCamera = !this.useFrontCamera;
          this.startCamera();
        },
        stopCamera() {
          if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
            this.cameraActive = false;
          }
        },
        init() {
          this.listaTipoProfissional();
          this.listaEtapas();
          this.listaVisitasPendentes();
          this.listaVisitas();
        },
    },
    mounted() {
        this.getIp();
        this.getLocalizacaoPorIP();
        this.carregarClientes();
        this.carregarCidades2();
        this.carregarCidades();

    },
    watch: {
      searchClienteGestor: function (val) {
        this.carregarClientesGestor(val);
      },
      search: function (val) {
        this.carregarClientes(val);
      },
      search2: function (val) {
        this.carregarClientes2(val);
      },
      searchCidade: function (val){
        this.carregarCidades(val);
      },
      searchCidade2: function (val){
        this.carregarCidades2(val);
      },
      "tipoCliente"(val){
        if(val){
          this.clientes = [];
          this.carregarClientesGestor('');
        }else{
          this.carregarClientes();
        }
      },
      "formVisita.cliente"(novoValor) {

          if(this.tipoCliente === false){
            console.log("Novo Cliente Selecionado:", novoValor); // Verifica o ID do cliente selecionado
            console.log("Lista de Clientes Disponível:", this.clientes); // Confirma os dados carregados

            const clienteSelecionado = this.clientes.find(c => c.idcliente === novoValor);

            console.log("Cliente Encontrado:", clienteSelecionado); // Confirma se o cliente foi encontrado

            this.formVisita.clienteNome = clienteSelecionado ? clienteSelecionado.nome : "";
            this.formVisita.cpfcnpj = clienteSelecionado ? clienteSelecionado.cpfcnpj : "";
            this.formVisita.clienteFone = clienteSelecionado ? clienteSelecionado.endereco.telefoneComercial : "";
            this.formVisita.enderecoRua = clienteSelecionado ? clienteSelecionado.endereco.endereco : "";
            this.formVisita.enderecoBairro = clienteSelecionado ? clienteSelecionado.endereco.bairro : "";
            this.formVisita.enderecoNumero = clienteSelecionado ? clienteSelecionado.endereco.numero : "";
            this.formVisita.enderecoCidade = clienteSelecionado ? clienteSelecionado.idcidade : "";
            this.formVisita.enderecoIdCidade = clienteSelecionado ? clienteSelecionado.idcidade : "";
          }

      },
      "formVisita.clienteNome"(novoValor){
        console.log("Novo Cliente Selecionado:", novoValor); // Verifica o ID do cliente selecionado
        console.log("Lista de Clientes Disponível:", this.clientesGestor); // Confirma os dados carregados

        const clienteSelecionado = this.clientesGestor.find(c => c.NOME === novoValor);

        console.log("Cliente Encontrado:", clienteSelecionado); // Confirma se o cliente foi encontrado

        this.formVisita.clienteFone = clienteSelecionado ? clienteSelecionado.TELEFONE : "";
        this.formVisita.cpfcnpj = clienteSelecionado ? clienteSelecionado.CNPJCPF : "";
        this.formVisita.cliente = clienteSelecionado ? clienteSelecionado.IDCLIFOR : "";
      },
      "formVisita.dataConclusao"(newValue) {
        if (newValue) {
          this.verificaDataConclusao();
        }
      },
      "buscaPersonalizada.tipo"(newValue) {
        if (newValue) {
          this.formVisita.cliente = '';
          this.formVisita.clienteFone = '';

          this.editaForm.cliente = '';
          this.editaForm.clienteFone = '';
        }
      },
    },
    beforeDestroy() {
    },
    created() {
      this.init();
    },
};
</script>

<style scoped>
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.scrollable-content {
  max-height: calc(70vh - 120px); /* Ajuste conforme necessário */
  overflow-y: auto;
}


</style>